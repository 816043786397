<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<div class="member-index" v-loading="loading">
			<div class="member-top">
				<div class="info-wrap">
					<div class="info-top">
						<div class="avtar">
							<router-link to="/member/info">
								<img v-if="member.headimg" :src="$img(member.headimg)" @error="member.headimg = defaultHeadImage" />
								<img v-else :src="$img(defaultHeadImage)" />
							</router-link>
						</div>
						<div class="member-wrap">
							<template v-if="member">
								<div class="name member-name" v-if="member.nickname">
									<router-link to="/member/info">{{ member.nickname }}</router-link>
								</div>
								 
							</template>
							<div class="no-login name" v-else>未登录</div>
						</div>
					</div>
					<div class="account">
						<div class="content">
							 
							<div class="item">
								<router-link to="/member/account" class="item-content">
									<img src="@/assets/images/balance.png" alt />
									<div class="name">余额</div>
									<div class="num" v-if="member.balance || member.balance_money">
										{{ (parseFloat(member.balance) + parseFloat(member.balance_money)).toFixed(2) }}
									</div>
									<div class="num" v-else>0</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="collection">
					<div class="title">我的关注</div>
					<div class="xian"></div>
					<div class="item-wrap">
						<div class="item">
							<div class="num">{{ goodsTotal }}</div>
							<div class="collect">商品关注</div>
						</div>
					</div>
				</div>
			</div>
			<div class="memberorderlist" >
				<el-tabs v-model="showStatus" @tab-click="handleClick">
					<el-tab-pane label="现货订单" name="orderlist"></el-tab-pane>
					<el-tab-pane label="可售卖订单" name="prelist"></el-tab-pane> 
					<el-tab-pane label="交易历史" name="historylist"></el-tab-pane> 
					 
				</el-tabs>
			</div>
			<div class="member-bottom">
				
				<div class="my-order" v-show="showStatus=='orderlist'"> 
					<div class="order-item">
						<router-link to="/member/order_list?status=waitpay" class="item">
							<i class="iconfont icondaifukuan"></i>
							<div v-if="orderNum.waitPay" class="order-num">{{ orderNum.waitPay }}</div>
							<div class="name">待付款</div>
						</router-link>
						<router-link to="/member/order_list?status=waitsend" class="item">
							<i class="iconfont icondaifahuo"></i>
							<div v-if="orderNum.readyDelivery" class="order-num">{{ orderNum.readyDelivery }}</div>
							<div class="name">待发货</div>
						</router-link>
						<router-link to="/member/order_list?status=waitconfirm" class="item">
							<i class="iconfont icontubiaolunkuo-"></i>
							<div v-if="orderNum.waitDelivery" class="order-num">{{ orderNum.waitDelivery }}</div>
							<div class="name">待收货</div>
						</router-link>
						<router-link to="/member/order_list?status=waitrate" class="item">
							<i class="iconfont icondaipingjia"></i>
							<div v-if="orderNum.waitEvaluate" class="order-num">{{ orderNum.waitEvaluate }}</div>
							<div class="name">待评价</div>
						</router-link>
						<router-link to="/member/activist" class="item">
							<i class="iconfont iconshouhou"></i>
							<div v-if="orderNum.refunding" class="order-num">{{ orderNum.refunding }}</div>
							<div class="name">退款/售后</div>
						</router-link>
					</div>
					<div v-if="orderList.length">
						<div class="order-goods-wrap" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
							<div class="order-goods" v-for="(goodsItem, goodsIndex) in orderItem.order_goods" :key="goodsIndex">
								<div class="goods-item">
									<div class="goods-img" @click="$router.pushToTab({ path: '/sku-' + goodsItem.sku_id })">
										<img :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageErrorOrder(orderIndex, goodsIndex)" />
									</div>
									<div class="info-wrap">
										<div class="goods-name" @click="$router.pushToTab({ path: '/sku-' + goodsItem.sku_id })">{{ goodsItem.sku_name }}</div>
										<div class="price">￥{{ goodsItem.price }}</div>
									</div>
									<div class="payment">{{ orderItem.order_status_name }}</div>
									<div class="goods-detail" @click="orderDetail(orderItem)">
										<p>查看详情</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="empty" v-else>
						<img src="@/assets/images/member-empty.png" alt />
						<div><router-link to="/">您买的东西太少了，这里都空空的，快去挑选合适的商品吧！</router-link></div>
					</div>
				</div>
				
				
				
				
				
				<div class="bottom-right" v-show="0">
					<div class="my-foot">
						<div class="title">我的足迹</div>
						<div class="xian"></div>
						<div class="foot-content" v-for="(item, index) in footList" :key="item.goods_id">
							<div class="foot-item" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
								<div class="foot-img"><img :src="$img(item.sku_image, { size: 'mid' })" @error="imageErrorFoot(index)" /></div>
								<div class="foot-info">
									<div class="foot-name">{{ item.goods_name }}</div>
									<div class="foot-price">￥{{ item.discount_price }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				
				
				<el-card class="box-card order-list" v-show="showStatus=='prelist'">
				<div v-loading="loading">
					<nav>
						<li>商品信息</li>						 
						<li>手数</li>
						<li>实际成本</li>
						<li>订单状态</li>
						<li>操作</li>
					</nav>
				
					<div class="list" v-if="orderList2.length > 0">
						<div class="item" v-for="(orderItem, orderIndex) in orderList2" :key="orderIndex">
							<div class="head">
								<span class="create-time">{{ $util.timeStampTurnTime(orderItem.create_time) }}</span>
								<span class="order-no">订单号：{{ orderItem.order_no }}</span>
								<!-- <router-link :to="'/shop-' + orderItem.site_id" target="_blank">{{ orderItem.site_name }}</router-link> -->
								<span class="order-type" v-if="orderItem.sale_id==0">直买单</span>
								<span class="order-type" v-else>竞买单</span> 
							</div>
							<ul v-for="(goodsItem, goodsIndex) in orderItem.order_goods" :key="goodsIndex">
								<li>
									<div class="img-wrap" @click="$router.pushToTab('/presku-' + goodsItem.sku_id)">
										<img :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(orderIndex, goodsIndex)" />
									</div>
									<div class="info-wrap" @click="$router.pushToTab('/presku-' + goodsItem.sku_id)">
										<h5>{{ goodsItem.sku_name }}</h5>
										<div>交付时间：{{goodsItem.dealtime}}</div> 
									</div> 
								 
								</li>
								 
								<li>
									<span>{{ orderItem.realnum }}</span>
								</li>
								<li>
									<span>￥{{ orderItem.realprice2 }}</span>
								</li>
								<template v-if="goodsIndex == 0">
									<li>
										<span class="ns-text-color" v-if="orderItem.order_status == 11">银行处理中 </span>
										<span class="ns-text-color" v-else-if="orderItem.order_status == 1">待交割 </span>
										<span class="ns-text-color" v-else>{{ orderItem.order_status_name }} </span>
										
										
									</li>
									<li>
										<span class="ns-text-color"  v-if="orderItem.order_status == 1&&orderItem.salelist.length>0">
											挂卖中
										</span> 
										<el-button type="primary" plain size="mini" v-if="orderItem.order_status == 1&&goodsItem.num>0" @click="$router.push({ path: '/member/ordersale', query: { order_goods_id: goodsItem.order_goods_id, order_id: orderItem.order_id } })">
											挂卖
										</el-button> 
										<el-button v-for="(saleItem, saleIndex) in orderItem.salelist" :key="saleIndex" type="primary" plain size="mini" v-if="orderItem.order_status == 1&&orderItem.salelist.length>0" @click="cancelsale(saleItem.order_sale_id)">
										 取消挂卖[{{saleItem.salesum}}手]
										</el-button>
										<el-link :underline="false" @click="orderhistory(orderItem)">交易历史</el-link> 
									</li>
								</template>
							</ul>
						</div>
					</div>
					<div v-else-if="!loading && orderList2.length == 0" class="empty-wrap">暂无相关订单</div>
				</div>
				</el-card>
				<div class="historydiv" v-show="showStatus=='historylist'">
					<nav> 
						<li>订单编号</li>
						<li>买方</li>
						<li>卖方</li>
						<li>商品信息</li>
						<li>售价</li>
						<li>数量</li>
						<li>备注</li> 
						<li>时间</li> 
						<li>操作</li>
					</nav>
					<ul v-for="(hitem,hindex) in orderList3"> 
						<li>{{hitem.order_no}}</li>
						<li>{{hitem.buyer_name}}</li>
						<li>{{hitem.seller_name}}</li>
						<li>{{hitem.goods_name}}</li>
						<li>{{hitem.price}}</li>
						<li>{{hitem.sum}}</li>
						<li>{{hitem.detail}}</li>
						<li>{{hitem.create_time}}</li>
						<li><el-link v-if="hitem.order_id>0" :underline="false" @click="orderDetail(hitem)">订单详情</el-link></li>
					</ul>
				</div>
				
				
			</div>
			
			
			
			
			
		</div>
	</div>
</template>

<script>
	import {
		orderNum,
		couponNum,
		footprint,
		levelList
	} from '@/api/member/index';
	import {
		goodsCollect
	} from '@/api/member/collection';
	import {
		apiOrderList,
		apiSaleHistoryList
	} from '@/api/order/order';
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'member',
		components: {},
		data: () => {
			return {
				couponNum: 0,
				orderNum: {
					waitPay: 0, //待付款
					readyDelivery: 0, //待发货
					waitDelivery: 0, //待收货
					refunding: 0 // 退款中
				},
				orderList: [],
				orderList2: [],
				orderList3: [],
				orderStatus: 'all',
				showStatus: 'orderlist',
				footInfo: {
					page: 1,
					page_size: 6
				},
				total: 0,
				footList: [],
				currentPage: 1,
				loading: true,
				goodsTotal: 0,
				state: '',
				growth: '',
				levelList: [],
				member_level: {},
				progress: 0,
				yes: true
			};
		},
		created() {
			this.getCouponNum();
			this.getOrderNum();
			this.getOrderList();
			this.getOrderList2();
			this.getOrderList3();			
			this.getFootprint();
			this.getGoodsCollect();
			// this.$store.dispatch("member/member_detail")

			this.$forceUpdate()
		},

		computed: {
			...mapGetters(['defaultHeadImage', 'defaultGoodsImage', 'member'])
		},
		watch: {
			member: {
				handler() {
					console.log(this.member)
					if (this.member) this.getLevelList();
				},
				immediate: true,
				deep: true,

			}
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false;
			}, 300);
		},
		methods: {
			getLevelList() {
				levelList()
					.then(res => {
						if (res.data && res.code == 0) {
							this.levelList = res.data;
							let listIndex = this.levelList.findIndex(item => item.level_id == this.member.member_level);

							const max = this.levelList.length;
							if (max > listIndex + 1) {
								if (this.member.growth > this.levelList[listIndex + 1].growth) {
									this.progress = 100;
								} else {
									this.progress = (this.member.growth / this.levelList[listIndex + 1].growth) * 100;
								}
							} else {
								this.progress = 100;
							}
						} else {
							this.$message.error(err.message);
						}
					})
					.catch(err => {
						console.log(err.message);
					});
			},
			//获取优惠券数量
			getCouponNum() {
				couponNum()
					.then(res => {
						this.couponNum = res.data;
					})
					.catch(err => {
						console.log(err.message);
					});
			},
			handleClick(tab, event) { 
				this.showStatus = tab.name;
				 
			},
			//获取订单数量
			getOrderNum() {
				orderNum({
						order_status: 'waitpay,waitsend,waitconfirm,waitrate,refunding'
					})
					.then(res => {
						console.log(res,'获取订单数量')
						if (res.code == 0) {
							this.orderNum.waitPay = res.data.waitpay;
							this.orderNum.readyDelivery = res.data.waitsend;
							this.orderNum.waitDelivery = res.data.waitconfirm;
							this.orderNum.waitEvaluate = res.data.waitrate;
							this.orderNum.refunding = res.data.refunding;
						}
					})
					.catch(err => {
						console.log(err.message);
					});
			},
			//获取订单列表
			getOrderList() {
				apiOrderList({
						order_status: this.orderStatus,
						page: 1,
						page_size: 3
					})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.orderList = res.data.list;
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			getOrderList2() {
				apiOrderList({
						order_status: 'all',
						page: 1,
						page_size: 3000,
						orderbuytype: 2
					})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.orderList2 = res.data.list;
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			getOrderList3() {
				apiSaleHistoryList({ 
						page: 1,
						page_size: 3000 
					})
					.then(res => {
						console.log("...............",JSON.stringify(res))
						if (res.code == 0 && res.data) {
							this.orderList3 =res.data;
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			
			//获取我的足迹
			getFootprint() {
				footprint(this.footInfo)
					.then(res => {
						if (res.code == 0 && res.data) {
							this.footList = res.data.list;
							this.total = res.data.count;
						}
					})
					.catch(err => {
						console.log(err.message);
					});
			},
			orderDetail(data) {
				switch (parseInt(data.order_type)) {
					case 2:
						// 自提订单
						this.$router.push({
							path: '/member/order_detail_pickup',
							query: {
								order_id: data.order_id
							}
						});
						break;
					case 3:
						// 本地配送订单
						this.$router.push({
							path: '/member/order_detail_local_delivery',
							query: {
								order_id: data.order_id
							}
						});
						break;
					case 4:
						// 虚拟订单
						this.$router.push({
							path: '/member/order_detail_virtual',
							query: {
								order_id: data.order_id
							}
						});
						break;
					default:
						this.$router.push({
							path: '/member/order_detail',
							query: {
								order_id: data.order_id
							}
						});
						break;
				}
			},
			orderhistory(data){
				this.$router.push({
					path: '/member/order_sale_history',
					query: {
						order_id: data.first_order_no,
						my_order_no: data.order_no
					}
				});
			},
			imageErrorOrder(orderIndex, goodsIndex) {
				this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
			},
			imageErrorFoot(index) {
				this.footList[index].sku_image = this.defaultGoodsImage;
			},
			getGoodsCollect() {
				goodsCollect()
					.then(res => {
						this.goodsTotal = res.data.count;
					})
					.catch(err => {
						this.loading = false;
						console.log(err.message);
					});
			}
		}
	};
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}
	.memberorderlist{
		margin-top:20px;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #ffffff;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.member-index {
		.member-top {
			width: 100%;
			display: flex;

			.info-wrap {
				width: 75%;
				height: 160px;
				border: 1px solid #e9e9e9;
				background: #ffffff;
				display: flex;

				.info-top {
					display: flex;
					align-items: center;
					margin: 22px;
					margin-right: 0;
					width: 300px;
					border-right: 1px solid #e9e9e9;

					.avtar {
						width: 84px;
						height: 84px;
						margin: 20px 0 10px 0;
						border: 1px solid #e9e9e9;
						border-radius: 50%;
						overflow: hidden;
						display: block;
						cursor: pointer;
						margin-left: 21px;
						text-align: center;
						line-height: 84px;

						img {
							display: inline-block;
						}
					}

					.member-wrap {
						margin-left: 20px;

						.name {
							font-size: 18px;
							font-weight: 600;
							cursor: pointer;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.growth {
							display: flex;
							align-items: center;
						}

						.el-progress {
							width: 100px;
						}

						.level {
							padding: 3px 4px;
							line-height: 1;
							color: #ffffc1;
							margin: 6px 0;
							cursor: default;
							background: linear-gradient(#636362, #4e4e4d);
							border-radius: 3px;
							display: inline-block;
						}
					}
				}

				.account {
					width: 400px;
					display: flex;
					align-items: center;

					.content {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;

						.item {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							.item-content {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
							}

							img {
								width: 50px;
								height: 50px;
							}

							.name {
								margin-top: 5px;
								color: #666666;

								&:hover {
									color: $base-color;
								}
							}

							.num {
								color: $ns-text-color-black;
							}
						}
					}
				}
			}

			.collection {
				background: #ffffff;
				margin-left: 20px;
				width: 210px;
				border: 1px solid #e9e9e9;
				padding-left: 20px;

				.title {
					padding: 10px 0;
					display: inline-block;
					border-bottom: 1px solid $base-color;
				}

				.xian {
					height: 1px;
					background: #f1f1f1;
				}

				.item-wrap {
					display: flex;
					justify-content: center;
					align-items: center;

					.item {
						flex: 1;
						margin-top: 37px;

						.num {}

						.collect {
							color: #666666;
						}
					}
				}
			}
		}

		.member-bottom {
			width: 100%; 
			margin-top: 15px;
			height: 553px;
			 
			overflow: hidden;

			.my-order {
				width: 100% !important;
				 
				background-color: #ffffff;

				.order-title {
					font-size: $ns-font-size-base;
					padding: 10px 0;
					margin-left: 15px;
					border-bottom: 1px solid $base-color;
					display: inline-block;
				}

				.xian {
					height: 1px;
					background: #f1f1f1;
					margin-left: 15px;
				}

				.order-item {
					display: flex;
					justify-content: center;
					align-content: center;

					.item {
						flex: 1;
						text-align: center;
						height: 85px;
						padding-top: 20px;
						cursor: pointer;
						position: relative;

						&:hover {
							background: #ffffff;
							box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
						}

						.order-num {
							position: absolute;
							top: 23px;
							right: 25px;
							background: $base-color;
							border-radius: 50%;
							width: 18px;
							height: 18px;
							line-height: 18px;
							color: #ffff;
						}

						i {
							font-size: 30px;
						}

						.name {
							font-size: $ns-font-size-base;
						}
					}
				}

				.order-goods {
					.goods-item {
						display: flex;
						padding: 14px;
						border-top: 1px solid #f1f1f1;

						.goods-img {
							flex: 1;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;

							img {
								width: 60px;
								height: 60px;
							}
						}

						.info-wrap {
							flex: 3;
							width: 80%;

							.goods-name {
								height: 46px;
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
							}

							.price {
								color: $base-color;
							}
						}

						.payment {
							flex: 2;
							display: flex;
							align-items: center;
							justify-content: center;
							text-overflow: ellipsis;
						}

						.goods-detail {
							flex: 2;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;

							&:hover {
								color: $base-color;
							}
						}
					}
				}
			}

			.bottom-right {
				.my-foot {
					background: #ffffff;
					margin-left: 20px;
					width: 230px;

					.title {
						font-size: $ns-font-size-base;
						padding: 10px 0;
						display: inline-block;
						border-bottom: 1px solid $base-color;
						margin: 0 15px;
					}

					.xian {
						margin-left: 15px;
						background: #f1f1f1;
						height: 1px;
					}

					.foot-content {
						.foot-item {
							display: flex;
							padding: 10px 0;
							margin: 0 15px;

							.foot-img {
								width: 57px;
								height: 57px;
								cursor: pointer;

								img {
									width: 100%;
									height: 100%;
								}
							}

							.foot-info {
								margin-left: 5px;
								display: flex;
								flex-direction: column;
								justify-content: space-between;

								.foot-name {
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									line-height: 1;
									width: 140px;
								}

								.foot-price {
									color: $base-color;
								}
							}
						}
					}
				}
			}
		}
	}

	.empty {
		text-align: center;
		margin-top: 65px;
	}
	
	
	.order-list {
		width:100%;
		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			margin-bottom: 10px;
			border-bottom: 1px solid #eeeeee;
	
			li {
				float: left;
	
				&:nth-child(1) {
					width: 45%;
				}
	
				&:nth-child(2) {
					width: 10%;
				}
	
				&:nth-child(3) {
					width: 10%;
				}
	
				&:nth-child(4) {
					width: 10%;
				}
	
				&:nth-child(5) {
					width: 15%;
				}
	
				&:nth-child(6) {
					width: 10%;
				}
			}
		}
	
		.list {
			width:100%;
			.item {
				margin-bottom: 20px;
				border: 1px solid #eeeeee;
				border-top: 0;
	
				.head {
					padding: 8px 10px;
					background: #f7f7f7;
					font-size: 12px;
	
					.create-time {
						margin-right: 10px;
					}
	
					border-bottom: 1px solid #eeeeee;
	
					a {
						margin: 0 10px 0 20px;
					}
	
					.order-type {
						margin-left: 30px;
					}
				}
				.order-time {
					display: flex;
					align-items: center;
					font-size: 10px;
				}
			}
	
			ul {
				background-color: #fff;
				padding: 10px;
				overflow: hidden;
	
				li {
					float: left;
					line-height: 60px;
	
					&:nth-child(1) {
						width: 45%;
						line-height: inherit;
	
						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}
	
						.info-wrap {
							margin-left: 70px;
	
							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;
	
								&:hover {
									color: $base-color;
								}
							}
	
							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}
	
					&:nth-child(2) {
						width: 10%;
					}
	
					&:nth-child(3) {
						width: 10%;
					}
	
					&:nth-child(4) {
						width: 10%;
					}
	
					&:nth-child(5) {
						width: 15%;
						line-height: 30px;
	
						a {
							display: block;
						}
					}
	
					&:nth-child(6) {
						width: 10%;
						line-height: initial;
	
						button {
							margin-left: 0;
							margin-bottom: 10px;
	
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	
		.empty-wrap {
			text-align: center;
			padding: 10px;
		}
	}
	
	.historydiv{
		background-color: #FFFFFF;
		height: 500px; 
		padding:20px;
		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			margin-bottom: 10px;
			border-bottom: 1px solid #eeeeee;
			
			li {
				float: left;
			    &:nth-child(1) {
			    	width: 20%;
			    }
			    	
			    &:nth-child(2) {
			    	width: 10%;
			    }
			    	
			    &:nth-child(3) {
			    	width: 10%;
			    }
				
				&:nth-child(4) {
					width: 10%;
				}
				&:nth-child(5) {
					width: 10%;
				}
				&:nth-child(6) {
					width: 6%;
				}
				&:nth-child(7) {
					width: 8%;
				}
				&:nth-child(8) {
					width: 16%;
				}
				&:nth-child(9) {
					width: 10%;
				}
				 
			}
		}
		ul{
		 height:35px;
		 line-height:35px;
		 
		 li{
			 float: left;
			  &:nth-child(1) {
			  	width: 20%;
			  }
			  	
			  &:nth-child(2) {
			  	width: 10%;
			  }
			  	
			  &:nth-child(3) {
			  	width: 10%;
			  }
			  
			  &:nth-child(4) {
			  	width: 10%;
			  }
			  &:nth-child(5) {
			  	width: 10%;
			  }
			  &:nth-child(6) {
			  	width: 6%;
			  }
			  &:nth-child(7) {
			  	width: 8%;
			  }
			  &:nth-child(8) {
			  	width: 16%;
			  }
			  &:nth-child(9) {
			  	width: 10%;
			  }
			 img{
				 width:50px;
				 height:50px;
				 border-radius: 5%;
				  
			 }
		 }	
		}
	}
</style>
